/* eslint-disable no-unused-vars */
import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import FormRequestAction from "../../static-data/FormRequestAction";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import {
  SCORPIONS_URL,
  METALICA_URL,
} from "../urls/base-urls";
import mapperToIssInformationRequest from "./tax-license-mapper";
import ServiceRequestType from "../../static-data/ServiceRequestType";

export async function getISSInformation(crm) {
  const resp = await axios.get(
    `${SCORPIONS_URL}/doctor-information-iss/?crm=${crm}`
  );
  return resp.data;
}

export async function updateIssInformation(formData, files) {
  var originalData = mapperToIssInformationRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToIssInformationRequest(
    formData,
    FormRequestAction.update
  );
  const resp = await axios.post(
    `${SCORPIONS_URL}/update-information-data`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      files,
      ServiceRequestType.DOCTOR_TAXES_AND_PERMIT_INFORMATION_ISS,
      null,
      changedData
    )
  );
  return resp.data;
}
